import React from 'react';

const MyPage = () => {
    return (
        <React.Fragment>
            마이페이지
        </React.Fragment>
    );
  }

  export default MyPage;
