import React from 'react';

const SignupPage = () => {
    return (
        <React.Fragment>
            회원가입 페이지
        </React.Fragment>
    );
  }
  
  export default SignupPage;