import React, { Fragment } from 'react';
import Container from 'react-bootstrap/Container';

const MainPage = () => {
    return (
        <Fragment>
             <Container>
                메인페이지                
             </Container>
        </Fragment>
    );
  }
  
  export default MainPage;